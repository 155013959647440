<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img :src="user.photoURL || avatar" max-height="50" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="user.displayName" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children && item.permission" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else-if="item.permission" :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <v-divider></v-divider>
    <template v-slot:append>
      <base-item
        :item="{
          title: $t('logout'),
          icon: 'mdi-account-arrow-right',
        }"
        @click.native="sair()"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import firebase from "firebase/app";
// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "dashboard",
        to: "/",
      },
      {
        icon: "mdi-account",
        title: "user",
        to: "/pages/profile",
      },
      {
        icon: "mdi-school",
        title: "courses",
        to: "/pages/courses",
        meta: {
          isAdmin: true,
          franquia: true,
        },
      },
      // {
      //   icon: "mdi-domain",
      //   title: "franquias",
      //   to: "/pages/franquias",
      //   meta: {
      //     isAdmin: true,
      //     franquia: false,
      //   },
      // },
      {
        icon: "mdi-cast-education",
        title: "catalog",
        to: "catalog",
        meta: {
          isAdmin: false,
          franquia: false,
        },
      },
      {
        icon: "mdi-account-group",
        title: "users",
        to: "/pages/users",
        meta: {
          isAdmin: true,
          franquia: true,
        },
      },
    ],
  }),

  computed: {
    ...mapState(["barColor", "barImage", "user", "avatar"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  methods: {
    ...mapMutations({
      setUser: "SET_USER",
    }),
    mapItem(item) {
      const isAdmin = item.meta ? item.meta.isAdmin : false;
      const franquia = item.meta ? item.meta.franquia : false;
      // console.log(item)
      // console.log(this.user.franquia)
      if(this.user.franquia.key ? franquia:true){

      }else{
        console.log(item)
      }
      // console.log(this.user.franquia ? !franquia:true)
      // console.log(this.user)
      // console.log( this.user.franquia ? franquia:true)
      // console.log(isAdmin ? (this.user.isAdmin ? true : false) : true)
      item.permission=isAdmin ? (this.user.isAdmin ? (this.user.franquia.key ? franquia:true) : false) : true
      // console.log(item.permission)
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      };
      
    },
    sair() {
      localStorage.removeItem("user");
      this.setUser({});
      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.reload();
          // console.log('Dashboard')
          // this.$router.replace("Login");
          // this.$router.push("/login");
        });
    },
  },
  mounted() {
    // console.log(this.user)
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
        margin-right: 24px
        margin-left: 12px !important

        +rtl()
        margin-left: 24px
        margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
        padding-left: 8px

        +rtl()
        padding-right: 8px

      .v-list-group__header
        +ltr()
        padding-right: 0

        +rtl()
        padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
          margin-right: 8px

          +rtl()
          margin-left: 8px
</style>
